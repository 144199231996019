<template>
    <div>
        <b-container>
            <section class="error-page">
                <h2 class="error-page__title">404</h2>
                <p class="error-page__text">Page not found</p>
                <b-row class="justify-content-center mt-5">
                    <router-link to="/" class="details-button"><simple-line-icons icon="arrowRight" size="small" color="#504e70" /> Home page</router-link>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
export default {
  components: {
    SimpleLineIcons
  }
}
</script>
